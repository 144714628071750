import React from 'react';
import { FaGoogle, FaDollarSign, FaTools, FaDatabase, FaRocket } from 'react-icons/fa';
import '../css/Workway.css';

const Workway = () => {
  const features = [
    {
      icon: <FaGoogle className="icon" />,
      title: "Google Workspace 판매 및 최적화",
      description: "Google Workspace의 다양한 플랜을 합리적인 가격으로 제공하며, 신속한 기술 지원을 보장합니다."
    },
    {
      icon: <FaTools className="icon" />,
      title: "AppSheet 기반 CRM 및 비용 처리",
      description: "AppSheet를 활용하여 CRM 시스템과 비용 처리 기능을 간편하게 구축할 수 있습니다."
    },
    {
      icon: <FaDatabase className="icon" />,
      title: "Google 드라이브 자동 백업",
      description: "API를 이용해 데이터를 Google 드라이브에 자동으로 백업하여 안전하게 관리합니다."
    },
    {
      icon: <FaDollarSign className="icon" />,
      title: "경쟁력 있는 가격",
      description: "최대한 할인된 가격으로 Google Workspace를 제공하며 높은 비용 효율성을 보장합니다."
    },
    {
      icon: <FaRocket className="icon" />,
      title: "신속하고 전문적인 서비스",
      description: "빠르고 전문적인 기술 지원으로 고객 만족도를 극대화합니다."
    }
  ];

  return (
    <div className="container">
      <header className="workway-header">
        <h1>Workway</h1>
        <p>Google Workspace 전문 서비스 제공</p>
      </header>

      <section className="workway-intro">
        <h2>회사 소개</h2>
        <p>워크웨이는 Google Workspace를 전문적으로 판매하고, 이를 통해 기업의 디지털 업무 환경을 혁신적으로 개선하는 IT 솔루션 제공 회사입니다.</p>
      </section>

      <section className="workway-features">
        <h2>워크웨이의 강점</h2>
        <div className="card-container">
          {features.map((feature, index) => (
            <div key={index} className="card">
              {feature.icon}
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="workway-contact">
        <h2>문의하기</h2>
        <p>Google Workspace와 워크웨이의 다양한 솔루션에 대해 더 알고 싶으신가요?</p>
        <p>지금 바로 문의하시면 최적의 디지털 업무 환경을 설계해드립니다!</p>
        <p><strong>전화:</strong> 010-1234-5678</p>
        <p><strong>이메일:</strong> support@workway.com</p>
      </section>
    </div>
  );
};

export default Workway;
