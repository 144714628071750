import React from "react";
import "../css/Pricing.css";

function Pricing() {
  const plans = [
    {
      name: "기본",
      description: "소규모 팀을 위한 경제적인 옵션입니다.",
      price: "$5/월",
      features: ["15GB 저장공간", "기본 지원", "보안 및 관리 도구"],
    },
    {
      name: "중소기업",
      description: "중소기업에 적합한 기능과 저장 용량을 제공합니다.",
      price: "$15/월",
      features: ["1TB 저장공간", "고급 지원", "팀 협업 도구"],
    },
    {
      name: "엔터프라이즈",
      description: "대규모 조직을 위한 고급 기능과 맞춤형 지원을 제공합니다.",
      price: "$25/월",
      features: ["무제한 저장공간", "24/7 전문가 지원", "고급 보안 및 분석"],
    },
  ];

  return (
    <div className="pricing-container">
      <h1 className="pricing-title">요금제</h1>
      <div className="pricing-grid">
        {plans.map((plan, index) => (
          <div key={index} className="pricing-card">
            <h2 className="plan-name">{plan.name}</h2>
            <p className="plan-description">{plan.description}</p>
            <span className="plan-price">{plan.price}</span>
            <ul className="plan-features">
              {plan.features.map((feature, idx) => (
                <li key={idx}>{feature}</li>
              ))}
            </ul>
            <button className="btn-select">선택</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Pricing;
