import React from 'react';


function Solutions() {
  return (
    <div className="solutions">
      <h1>비즈니스 솔루션</h1>
      <ul>
        <li><strong>회의 및 협업:</strong> Meet, Chat, Calendar 등을 통해 팀워크 강화.</li>
        <li><strong>데이터 저장 및 공유:</strong> Drive로 안전하게 데이터 저장 및 공유.</li>
        <li><strong>보안:</strong> 강력한 보안 기술로 데이터 보호.</li>
      </ul>
    </div>
  );
}

export default Solutions;