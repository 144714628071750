import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../css/Navbar.css";
import logo from "../images/logo/logo_transparent.png";
import { useAuth } from "../Component/AuthContext";

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { user, loginWithGoogle, logout } = useAuth();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="navbar-container">
      <nav className="navbar">
        {/* Logo */}
        <div className="navbar-logo">
          <Link to="/">
            <img src={logo} alt="WORKWAY Logo" />
          </Link>
        </div>

        {/* Hamburger Icon for Mobile */}
        <button
          className={`hamburger-icon ${isMobileMenuOpen ? "open" : ""}`}
          onClick={toggleMobileMenu}
          aria-expanded={isMobileMenuOpen}
          aria-label="Toggle navigation menu"
        >
          ☰
        </button>

        {/* Navigation Links */}
        <ul
          className={`nav-links ${isMobileMenuOpen ? "mobile-open" : ""}`}
          onClick={() => setIsMobileMenuOpen(false)}
        >
          <li><Link to="/">홈</Link></li>
          <li><Link to="/workway">Workway 소개</Link></li>
          <li><Link to="/features">기능 소개</Link></li>
          <li><Link to="/pricing">가격</Link></li>
          <li><Link to="/board">공지사항</Link></li>
          <li>
            <a
              href="https://forms.gle/ZKdgbNnfZRoTrZq57"
              target="_blank"
              rel="noopener noreferrer"
            >
              문의
            </a>
          </li>

          {/* Login/Logout Button */}
          {!user ? (
            <li className="auth-button">
              <button onClick={loginWithGoogle} className="btn-login">
                로그인
              </button>
            </li>
          ) : (
            <li className="user-info">
              안녕하세요, {user.displayName || "사용자"}님
              <button onClick={logout} className="btn-logout">
                로그아웃
              </button>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
