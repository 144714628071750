import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Features from "./pages/Features";
import Solutions from "./pages/Solutions";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
import Navbar from "./pages/Navbar";
import Board from "./pages/Board";
import Workway from "./pages/Workway";
import Footer from "./pages/Footer";
import { AuthProvider } from "./Component/AuthContext";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/features" element={<Features />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/board" element={<Board />} />
          <Route path="/workway" element={<Workway />} />
        </Routes>
        <Footer />
      </AuthProvider>
    </Router>
  );
}

export default App;
