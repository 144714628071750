import React from "react";
import "../css/home.css";
import Slider from "./Slide";
import { FaCogs, FaLaptopHouse, FaGavel } from "react-icons/fa";

function Home() {
  return (
    <div className="home">
      {/* Hero Section with Full-Screen Slider */}
      <header>
        <Slider />
      </header>
      <div className="container">
        {/* Card Section */}
        <section className="features">
          <h2 className="features-title">ABOUT WorkWay</h2>
          <p className="features-subtitle">
            Google Workspace 파트너, 디지털 혁신의 동반자.
          </p>
          <div className="feature-cards">
            <div className="card">
              <div className="icon">
                <FaCogs size={50} />
              </div>
              <h3>맞춤형 솔루션 제공</h3>
              <p>
                Google Workspace 파트너는 각 기업의 특정 요구와 업무 흐름에 맞춰
                최적화된 솔루션을 제공합니다. 클라우드 기반 도구를 통해
                비즈니스에 적합한 맞춤형 설정과 통합을 지원하여 생산성을 높이고
                업무 효율을 극대화합니다.
              </p>
              <button className="read-more">자세히 보기</button>
            </div>

            <div className="card">
              <div className="icon">
                <FaGavel size={50} />
              </div>
              <h3>전문가 지원</h3>
              <p>
                Google Workspace 파트너는 도입부터 운영까지 전문적인 컨설팅을
                제공합니다. 초기 설정, 사용자 교육, 그리고 최적의 활용 방안을
                제시하여 기업이 Google Workspace의 모든 기능을 최대한으로 활용할
                수 있도록 도와줍니다.
              </p>
              <button className="read-more">자세히 보기</button>
            </div>

            <div className="card">
              <div className="icon">
                <FaLaptopHouse size={50} />
              </div>
              <h3>신속한 문제 해결</h3>
              <p>
                실질 문제나 장애가 발생했을 때, 파트너는 신속하고 효과적인
                지원을 제공합니다. 문제 해결을 위한 빠른 대응과 지속적인
                유지보수 서비스를 통해 기업의 업무 중단을 최소화하고 원활한
                운영을 보장합니다.
              </p>
              <button className="read-more">자세히 보기</button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;
