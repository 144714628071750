import React from 'react';


function Contact() {
  return (
    <div className="contact">
      <h1>문의하기</h1>
      <form className="contact-form">
        <label htmlFor="name">이름:</label>
        <input type="text" id="name" name="name" required />
        
        <label htmlFor="email">이메일:</label>
        <input type="email" id="email" name="email" required />
        
        <label htmlFor="message">메시지:</label>
        <textarea id="message" name="message" rows="4" required></textarea>

        <button type="submit">보내기</button>
      </form>
    </div>
  );
}

export default Contact;