import React, { useState, useEffect } from "react";
import { database } from "../firebase";
import { ref, onValue, push, set, update, remove } from "firebase/database";
import { useAuth } from "../Component/AuthContext";

const Board = () => {
  const [posts, setPosts] = useState([]); // 공지 목록
  const [title, setTitle] = useState(""); // 제목 입력 상태
  const [content, setContent] = useState(""); // 내용 입력 상태
  const { user } = useAuth();

  // Firebase에서 데이터 읽기
  useEffect(() => {
    const postsRef = ref(database, "posts");
    onValue(postsRef, (snapshot) => {
      const data = snapshot.val();
      const postList = data
        ? Object.entries(data).map(([id, value]) => ({ id, ...value }))
        : [];
      setPosts(postList);
    });
  }, []);

  // 새 공지 추가
  const addPost = () => {
    if (title.trim() === "" || content.trim() === "") {
      alert("제목, 내용 및 작성자를 모두 입력하세요!");
      return;
    }
    const postsRef = ref(database, "posts");
    const newPostRef = push(postsRef);
    set(newPostRef, {
      title: title,
      content: content,
      author: user.email,
      views: 0,
      createdAt: new Date().toLocaleString(), // 작성 날짜
    })
      .then(() => {
        setTitle("");
        setContent("");
        closeModal(); // 모달 닫기
      })
      .catch((error) => console.error("글 추가 중 오류:", error));
  };

  // 조회수 증가
  const incrementViews = (id, currentViews) => {
    const postRef = ref(database, `posts/${id}`);
    update(postRef, { views: currentViews + 1 }).catch((error) =>
      console.error("조회수 증가 중 오류:", error)
    );
  };

  // 공지 삭제
  const deletePost = (id) => {
    const postRef = ref(database, `posts/${id}`);
    remove(postRef).catch((error) => console.error("글 삭제 중 오류:", error));
  };

  // 모달 열기 및 닫기
  const openModal = () => {
    const modal = document.getElementById("addPostModal");
    if (modal) modal.style.display = "block";
  };

  const closeModal = () => {
    const modal = document.getElementById("addPostModal");
    if (modal) modal.style.display = "none";
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">공지사항 게시판</h1>

      {user ? (
        <button className="btn btn-primary mb-4 pull-right" onClick={openModal}>
          글쓰기
        </button>
      ) : null}

      {/* 공지 리스트 (테이블 형식) */}
      <table className="table table-striped">
        <thead>
          <tr>
            <th>#</th>
            <th>제목</th>
            <th>작성자</th>
            <th>작성 날짜</th>
            <th>조회수</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {posts.map((post, index) => (
            <tr key={post.id}>
              <td>{index + 1}</td>
              <td
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => incrementViews(post.id, post.views)}
              >
                {post.title}
              </td>
              <td>{post.author}</td>
              <td>{post.createdAt}</td>
              <td>{post.views}</td>
              <td>
                {user && user.email === post.author ? (
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => deletePost(post.id)}
                  >
                    삭제
                  </button>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* 글쓰기 모달 */}
      <div id="addPostModal" className="modal" style={{ display: "none" }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">새 공지 작성</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
              ></button>
            </div>
            <div className="modal-body">
              <input
                type="text"
                className="form-control mb-3"
                placeholder="제목을 입력하세요"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <textarea
                className="form-control mb-3"
                placeholder="내용을 입력하세요"
                rows={5}
                value={content}
                onChange={(e) => setContent(e.target.value)}
              ></textarea>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={addPost}
              >
                저장
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Board;
