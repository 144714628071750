import React, { useState, useEffect } from 'react';
import '../css/Slider.css'; // Add your CSS file for styling
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi'; // Import 

import slide1 from '../images/slide/slide1.png';
import slide2 from '../images/slide/slide2.png';
import slide3 from '../images/slide/slide3.jpeg';
import slide4 from '../images/slide/slide4.jpeg';
import slide5 from '../images/slide/slide5.jpeg';


const slides = [
  {
    url: slide4,
    alt: 'Slide 1',
    title: 'Google Workspace',
    description: '안전한 맞춤 비즈니스 이메일과 디지털 증거 검색 및 보관 기능',
  },
  {
    url: slide3,
    alt: 'Slide 2',
    title: '클라우드 스토리지',
    description: '사용자당 5TB 클라우드 스토리지와 고급 보안 기능 제공',
  },
  {
    url: slide5,
    alt: 'Slide 3',
    title: '화상 회의 및 협업',
    description: '250명이 참여할 수 있는 화상 회의와 녹화 기능 지원',
  },
];

function Slider() {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatically transition slides every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const goToPrevious = () => {
    setCurrentIndex(currentIndex === 0 ? slides.length - 1 : currentIndex - 1);
  };

  const goToNext = () => {
    setCurrentIndex(currentIndex === slides.length - 1 ? 0 : currentIndex + 1);
  };

  return (
    <div className="slider">
      <div
        className="slider-wrapper"
        style={{
          transform: `translateX(-${currentIndex * 100}%)`,
          transition: 'transform 0.5s ease-in-out',
        }}
      >
        {slides.map((slide, index) => (
          <div className="slide" key={index} style={{ backgroundImage: `url(${slide.url})` }}>
            <div className="overlay">
              <h1>{slide.title}</h1>
              <p>{slide.description}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Navigation Arrows */}
       {/* Navigation Arrows */}
       <button className="arrow left-arrow" onClick={goToPrevious}>
        <FiArrowLeft size={30} color="white" />
      </button>
      <button className="arrow right-arrow" onClick={goToNext}>
        <FiArrowRight size={30} color="white" />
      </button>

      {/* Dots Navigation */}
      <div className="dots">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>
    </div>
  );
}

export default Slider;