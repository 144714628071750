import React from 'react';
import '../css/Features.css';
import { FaEnvelope, FaFileAlt, FaChartBar, FaSlideshare } from 'react-icons/fa';

function Features() {
  const features = [
    {
      name: "Gmail",
      description: "안전하고 강력한 이메일 서비스로, 효율적인 커뮤니케이션을 지원합니다.",
      icon: <FaEnvelope />,
    },
    {
      name: "Docs",
      description: "클라우드 기반의 문서 작성 및 편집 도구로, 실시간 협업이 가능합니다.",
      icon: <FaFileAlt />,
    },
    {
      name: "Sheets",
      description: "데이터 분석과 계산을 위한 스프레드시트 도구로, 다양한 함수와 시각화를 제공합니다.",
      icon: <FaChartBar />,
    },
    {
      name: "Slides",
      description: "매력적인 프레젠테이션 제작 도구로, 창의적인 슬라이드를 쉽게 만들 수 있습니다.",
      icon: <FaSlideshare />,
    },
  ];

  return (
    <div className="features-container">
      <h1 className="features-title">주요 기능</h1>
      <div className="features-grid">
        {features.map((feature, index) => (
          <div key={index} className="feature-card">
            <div className="feature-icon">{feature.icon}</div>
            <h2 className="feature-name">{feature.name}</h2>
            <p className="feature-description">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Features;
